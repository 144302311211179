export const UPDATE_CELL_VALUE = "UPDATE_CELL_VALUE";
export const UPDATE_DATA = "UPDATE_DATA";
export const INSERT_ROW = "INSERT_ROW";
export const DELETE_ROW = "DELETE_ROW";
export const UPDATE_GENERIC_INPUTS = "UPDATE_GENERIC_INPUTS";


export const updateCellValue = (payload) => {
    return { type: UPDATE_CELL_VALUE, payload };
};

export const updateData = (payload) => {
    return { type: UPDATE_DATA, payload };
};

export const insertRow = (payload) => {
    return { type: INSERT_ROW, payload };
};

export const deleteRow = (payload) => {
    return { type: DELETE_ROW, payload };
};

export const updateGenericInputs = (payload) => {
    return { type: UPDATE_GENERIC_INPUTS, payload };
};

const initialState = {
    count: 3,
    genericInputs: {
        cf:0.995,
        corr:0.50,
        extremeImpactPct: 0.95 
    },
    values: [
        {
            Name: "Data Breach",
            EI: 100000,
            id: 0
        },
        {
            Name: "Transaction error",
            EI: 1000000,
            id: 1
        },
        {
            Name: "Pricing and reporting",
            EI: 1000000,
            id: 2
        },
        {
            Name: "Third party failure",
            EI: 150000,
            id: 3
        },
        {
            Name: "Business resilience",
            EI: 300000,
            id: 4
        },
        {
            Name: "People management",
            EI: 1500000,
            id: 5
        },
        {
            Name: "Client mandate breach",
            EI: 2700000,
            id: 6
        },
        {
            Name: "Mis-selling",
            EI: 3900000,
            id: 7
        },
        {
            Name: "Fiduciary, conduct and culture",
            EI: 1500000,
            id: 8
        },
        {
            Name: "Client adminstration",
            EI: 2500000,
            id: 9
        },
    ]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_CELL_VALUE':
            const updatedValues = state.values.map(obj =>
                obj.id === action.payload.rowId ? { ...obj, [action.payload.column]: isNaN(Number(action.payload.value)) ? action.payload.value : Number(action.payload.value)  } : obj
            );
            return {...state, values: updatedValues}
        case 'UPDATE_DATA':
            return {...state, values: action.payload}
        case 'INSERT_ROW':
            const newValues = action.payload.concat(state.values);
            return {...state, values:newValues};
        case 'DELETE_ROW':
            const filteredvalues = state.values.filter(val => val.id != action.payload);
            return {...state, values:filteredvalues};
        case "UPDATE_GENERIC_INPUTS":
            return {...state, genericInputs:action.payload};
        default:
            return state;
    }
};