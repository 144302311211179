import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import Layout from "./Layout";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "./Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Home from "./Home";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#325875",
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    fileChoose: {
        marginLeft: '5%'
    },
    footer: {
        fontSize: 10,
        marginBottom: "10px",
        color: '#d9d9d9',
        letterSpacing: 3
    },
    btn: {
        // color: "#999999"
    }
}));

export default function RiskAggregation() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const sampleData = useSelector((state) => state?.inputs?.values);
    
    const [data, setData] = useState(sampleData);
    const [file, setFile] = useState();
    const [errorMsg, setErrorMsg] = useState(null);

    return (
        <div className={classes.root}>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Navbar />
                </Grid>
                <Grid item xs={12} className={classes.fileChoose}>
                </Grid>
                <Grid item xs={12} >
                    <Layout data={data} />
                </Grid>
                <Grid align="center" item xs={12}>
                    <Typography className={classes.footer} gutterBottom>
                        COPYRIGHT &#169; 2021 MONTE CARLO PLUS &#183; info[at]montecarloplus.com
                    </Typography>
                </Grid>
            </Grid>
            <Snackbar
                open={errorMsg !== null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                autoHideDuration={5000}
                onClose={() => {
                    setErrorMsg(null);
                }}
            >
                <Alert severity="error">{errorMsg}</Alert>
            </Snackbar>
        </div>
    );
}

// export default withAuthenticationRequired(RiskAggregation, {
//     onRedirecting: () => <Home />,
//   });