import * as React from 'react';
import { DataGrid, GridToolbar,  GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { makeStyles } from "@material-ui/core/styles";
import {v1} from 'uuid';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingLeft:"3%",
      paddingRight:"3%",
      color:'#d9d9d9',
      border: 'none',
      fontSize:"11px"
    },
    btn: {
      color: "#999999",
      fontSize:"10px"
  }
  }));

export default function EditableTableOutput(props) {
  const classes = useStyles();

  const columns = [
    {
        headerName: "Name",
        field: "Name",
        flex:2,
    },
    {
        headerName: "Expected Loss",
        field: "EL",
        type: "number",
        flex:2,
    },
    {
        headerName: "OpVaR",
        field: "VaR",
        type: "number",
        flex:2,
    }
];

const rows = props.data;
rows.forEach(element => {
    element['id']= v1()
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport className={classes.btn}/>
    </GridToolbarContainer>
  );
}

  return (
    <div style={{ height: 350, width: '100%', color:'#d9d9d9', }}>
      <DataGrid rows={rows} 
        columns={columns} 
        className={classes.root} 
        pageSize={10} 
        rowHeight={30} 
        autoPageSize={true}
        disableColumnMenu={true}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
