import React, {  useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ncdf from "../utils/normal_dist";
import NormSInv from "../utils/inverse_normal_dist";
import Dashboard from "./Dashboard";
import Grid from "@material-ui/core/Grid";
import InputSummary from "./InputSummary";
import Paper from "@material-ui/core/Paper";
import InputChart from "./InputChart";
import OutputChart from "./OutputChart";
import { makeStyles } from '@material-ui/core/styles';
import EditableTable from './EditableTable'
import EditableTableOutput from './EditableTableOutput'
import Alert from "./Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { gridColumnLookupSelector } from "@material-ui/data-grid";
import {matrix, multiply, transpose} from 'mathjs';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingLeft:"5%",
      paddingRight:"5%"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.primary,
      background: "#1e375e",
      height: 325,
      borderRadius: '5px'
    },
    btn:{
        color:'#999999'
    }
  }));

export default function ExcelReader(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector((state) => state?.inputs?.values);
    const genericInputs = useSelector((state) => state?.inputs?.genericInputs);
    const [inputData, setInputData] = useState(data)
    const [inputSummary, setInputSummary]=useState(computeInputSummary(inputData))

    const outputSummary = computeOutputSummary(inputData);

    useEffect(()=>{
        setInputData(data);
        setInputSummary(computeInputSummary(data));
    },[data])

    const [errorMsg, setErrorMsg] = useState(null);

    function computeInputSummary(inputData) {
        let sumEI = 0;
        let noOfCparties = inputData.length;
        let maxEI = 0;
        inputData.forEach(function (d) {
            sumEI += Number(d.EI);
            if (d.EI > maxEI) maxEI = d.EI;
        });
        let avgEI = sumEI / noOfCparties;

        const summary = {
            avgEI: avgEI,
            sumEI: sumEI,
            noOfCParties: noOfCparties,
            maxEI: maxEI,
        };
        return summary;
    }
    function computeOutputSummary(outputData) {
        let simpleSum = outputData.map((d) => d.EI).reduce((prev, next) => prev + next);
        let a = outputData.map((d)=>d.EI);
        let aT = transpose(a)
        let corrMatrix = createCorrMatrix(a.length,genericInputs.corr);
        let prod = multiply(corrMatrix, a);
        let prod2 = multiply(a, prod);
        const correlatedSum = Math.sqrt(prod2);
        // console.log('A for VaR', prod, prod2,correlatedSum);

        let summary = { simpleSum: simpleSum, correlatedSum: correlatedSum};
        return summary;
    }
    return (
        <div className={classes.root}>
                <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <EditableTable data={inputData}/>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    
                    <Paper className={classes.paper}>
                        <InputChart
                            indata={inputData}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <InputSummary summary={inputSummary} correlation={genericInputs?.corr} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                    <Dashboard
                        inputSummary={inputSummary}
                        outputSummary={outputSummary}
                    />
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                open={errorMsg !== null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                autoHideDuration={5000}
                onClose={() => {
                    setErrorMsg(null);
                }}
            >
                <Alert severity="error">{errorMsg}</Alert>
            </Snackbar>
        </div>
    );
    }
function computeEL(mu, sigma, likelyhood) {
    return Math.round(Math.exp(mu + (sigma*sigma)/2)*likelyhood);
}
function computeSigma(extremeImpact, typicalImpact, extremeImpactPct,){
    return(-NormSInv(extremeImpactPct)+ Math.sqrt(Math.pow(NormSInv(extremeImpactPct),2) + 
                                            4*(Math.log(extremeImpact)-Math.log(typicalImpact))))/2;
}
function computeMu(extremeImpact, extremeImpactPct, sigma){
    return Math.log(extremeImpact) - NormSInv(extremeImpactPct)*sigma;
}
function computeVaR(mu, sigma, cf, likelyhood) {
    return Math.round(Math.exp(mu + sigma*NormSInv(1-(1-cf)/likelyhood)));
}
  function createCorrMatrix(n,c){
    let matrix = [];
    for(var i=0; i<n; i++) {
        matrix[i] = [];
        for(var j=0; j<n; j++) {
            if (i==j) matrix[i][j] = 1; 
            else matrix[i][j] = c;
        }
    }
    return matrix;
  }