import React, { Component, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";

const style = {
    chart: {
      fontFamily: `font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
       "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale`
    },
};

export default function InputChart(props) {
  const indata = useSelector((state) => state.inputs.values);
    let options = {
        series: [{
        name: 'Extreme but Plausible Impact',
        type: 'column',
        data: indata.map((d) => d.EI)
      }],
        chart: {
        height: 350,
        type: 'line',
        foreColor: '#d9d9d9',
      },
      colors: ['#5f6483', '#798fb0'],
      stroke: {
        width: [0, 3],
        curve: 'smooth'
      },
      legend: {
        position: 'top',
      },
      title: {
        // text: 'Largest Exposures and their PDs',
        align: 'center',
        style:{
            fontWeight: 'normal',
        }
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1]
      },
      labels: indata.map((d) => d.Name),
      yaxis: [{
        title: {
          text: 'Extreme but Plausible Impact',
        },
        labels: {
            formatter: (val) => { return Math.round(val).toLocaleString()},
        },
      }]
      };

    return (
        <div className={style.chart}>
            <Chart
                options={options}
                series={options.series}
                height={345}
            />
        </div>
    );
}